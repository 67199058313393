import React from "react"
import loadable from "@loadable/component"
import "../../css/typography-roboto.css"
import "../../css/bvk-custom.css"
const ChatComponent = loadable(() =>
  import("../../components/Chat Iframe setVar")
)

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  return (
    <ChatComponent
      name="BVK"
      // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01
      botId="ddxt7bq4knqacu2q"
      avatar="false"
      domain="https://europe.directline.botframework.com/v3/directline"
      webSocket={false}
      // css="https://static.convaise.com/webchat/bvk/convaise-assistant-bvk-min.css"
      styleOptions={{
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#223B76",
        primaryFont: "'Roboto', 'Noto Sans', 'Arial', 'sans-serif'",
      }}
      styleCustomizations={{
        "--button-blue": "#233b76",
        "--button-blue-light": "#141718",
        "--button-green": "#008277",
        "--button-green-light": "#2b2b2b",
        "--button-red": "#c50f12",
        "--button-red-light": "#2b2b2b",
        "--button-orange": "#737373",
        "--button-orange-light": "#141718",
        "--button-border-radius": "3px",
        "--button-box-shadow": "2px 2px 4px 0 rgb(0 0 0 / 25%)",
        "--font-weight": "500",
        "--button-height": "44px",
        "--button-padding": "10px 16px !important",
      }}
      varId="a002d740-8b29-4491-ae3b-1053a36612f7"
      varValue="true"
    />
  )
}

export default Chat
